$ = jQuery;

/*Chat*/
if(redirectObject.chat_key != ''){
    function inIframeZopim(){try{return window.self!==window.top}catch(e){return!1}}
    if(!inIframeZopim())
    {window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set._.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');$.src='//v2.zopim.com/?'+redirectObject.chat_key+'';z.t=+new Date;$.type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script')}
}

/*Functions*/
var prefix = $('#main').attr('data-prefix');

/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

function scrollToElement(item) {
    var hT = item.offset().top,
        hH = item.outerHeight(true),
        wH = $(window).height(),
        wS = $(this).scrollTop();
    if (wS > (hT + hH - wH - hH)) {
        item.addClass('active');
    }
}

/**
 * Footer fort counter
 * @type {*|HTMLElement}
 */
function countWords(pagesInput) {
    $('#word_count_result').html(pagesInput.val() * 275);
}

function pagesP() {
    var pagesInput = $('#word_count');
    pagesInput.val(+pagesInput.val() + 1);
    countWords(pagesInput);
}

function pagesM() {
    var pagesInput = $('#word_count');
    if(pagesInput.val() > 1) {
        pagesInput.val(pagesInput.val() - 1);
        countWords(pagesInput);
    }
}

/**
 * Stars Rating
 */
function starsHover() {
    var ratingInner = $(".stars_rating.g_rating" + prefix + " " + ".g_rating_inner" + prefix),
        ratingInnerWidth = ratingInner.innerWidth(),
        ratingBlock = $(".stars_rating.g_rating" + prefix),
        id = $(".g_rating_block" + prefix + " " + ".count_number" + prefix).attr('data-id');
        ratingCount = $(".g_rating_block" + prefix + " " + ".count_number" + prefix + " " + "span");
    ratingBlock.on("click", function() {
        if (!getCookie('added_rating' + id) && !$(this).data("clicked")) {
            ratingCount.text(parseInt(ratingCount.text()) + 1);
            console.log(ratingCount.text());
            var data = {
                'action': 'add_rating_count',
                'count': ratingCount.html(),
                'id': id
            };
            $.ajax({
                url: '/wp-admin/admin-ajax.php',
                data:data,
                type:'POST',
                success:function(data){
                    if( data ) {
                        ratingCount.text(data);
                    }
                }
            });
        }
        setCookie('added_rating' + id,'true',10);
        $(this).data("clicked", !0)
    });
    if(!getCookie('added_rating' + id) && !$(this).data("clicked")){
        ratingBlock.on("mousemove", function(e) {
            if (!$(this).data("clicked")) {
                var ratingInnerWidth = e.clientX - $(this).offset().left;
                ratingInner.css("width", ratingInnerWidth)
            }
        });
        ratingBlock.on("mouseleave", function(e) {
            ratingInner.css("width", ratingInnerWidth)
        })
    }
}

$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    $(window).scroll(function () {
        showScrollTop();
    });

    /**
     * Chat
     */
    $('[data-chat]').on('click',function () {
        $zopim.livechat.window.show();
    });

    /**
     * Input type number
     */
    $('.word_count_m').on('click',function () {
        pagesM();
    });
    $('.word_count_p').on('click',function () {
        pagesP();
    });
    /**
     * Footer form datepicker
     */
    $( "#datepicker" ).datepicker({
        minDate: 0,
        maxDate: 62,
        dateFormat: "yy-mm-dd"
    });

    /**
     * Stars Hover
     */
    if($(".g_rating" + prefix)[0]){
        starsHover();
    }

    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('html, body').animate({scrollTop: top}, 1000);
    });
    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('#mainMenu').toggleClass("opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');
    });
    $('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    $(window).on('load', function () {
        if(!getCookie('privacy')){
            var privacy = $('#privacy');
            privacy.show();
        }
        $('#privacy .privacy_close').on('click',function () {
            $('#privacy').addClass('hide');
            setCookie('privacy','true',10);
        });
    });

    /**
     * Stats
     */
    /*Countdown*/
    (function() {
        var originalAddClassMethod = jQuery.fn.addClass;
        jQuery.fn.addClass = function(){
            // Execute the original method.
            var result = originalAddClassMethod.apply( this, arguments );
            // trigger a custom event
            jQuery(this).trigger('cssClassChanged');
            // return the original result
            return result;
        }
    })();
    /*Countdown init*/
    var countdown = $(".stats" + prefix);
    countdown.bind('cssClassChanged', function(){
        $('[data-count]', this).each(function() {
            var e = $(this),
                t = e.attr('data-count');
            $({
                countNum: e.text()
            }).animate({
                countNum: t
            }, {
                duration: 2000,
                easing: 'linear',
                step: function() {
                    e.text(Math.floor(this.countNum))
                },
                complete: function() {
                    e.text(Math.floor(this.countNum))
                }
            })
        });
    });
    if(siteBody.find('.stats' + prefix)[0]) {
        $(window).scroll(function () {
            scrollToElement(countdown);
        });
        scrollToElement(countdown);
    }
    /**
     * Hide and show content
     */
    $('.expand_button').on('click',function () {
        $(this).parent().prev().slideToggle(700);
        if($(this).hasClass('opened')){
            $(this).removeClass('opened');
            $('.table_of_content_menu' + prefix).slideDown();
        }
        else {
            $(this).addClass('opened');
            $('.table_of_content_menu'+ prefix).slideUp();
        }
    });

    var timeout = $('.content_block' + prefix).attr('data-timeout');
    if(timeout){
        setTimeout(function () {
            $('.expand_button').click();
        },timeout * 1000);
    }

    /**
     * FAQ
     */
    var faq_title = $('.faq_list_item' + prefix + ' .faq_list_title' + prefix),
        faq_item = $('.faq_list_item' + prefix);
    faq_title.click(function() {
        if($(this).hasClass('opened')){
            faq_title.removeClass('opened');
            $(this).next().slideToggle(300);
            faq_item.find('.faq_list_info' + prefix).slideUp(300);
        }
        else {
            faq_item.find('.faq_list_info' + prefix).slideUp(300);
            $(this).next().slideToggle(300);
            faq_title.removeClass('opened');
            $(this).addClass('opened');
        }
    });
    faq_title.eq(0).click();

    /**
     * Writers AJAX
     */
    $('.writers_loadmore' + prefix).on('click',function () {
        var button = $('.writers_loadmore' + prefix).parent();
        $(this).text('Loading...');
        var data = {
            'action': 'writers_load',
            'page' : writerObj.current_page,
            'max_pages' : writerObj.max_pages,
        };
        $.ajax({
            url:writerObj.ajaxurl,
            data:data,
            type:'POST',
            success:function(data){
                if( data ) {
                    button.children().text('Load More').parent().before(data);
                    writerObj.current_page++;
                    if (writerObj.current_page == writerObj.max_pages) button.remove();
                } else {
                    button.remove();
                }
            }
        });
    });

    /**
     * Table of content
     */
    $('.content_block' + prefix + ' .table_of_content'+prefix).toc({
        content: $('.content_block' + prefix + ' .left_content' + prefix),
        headings: redirectObject.toc_headings}).toc({
        content: $('.content_block' + prefix + ' .right_content' + prefix), headings: redirectObject.toc_headings
        });
    $('.table_of_content' + prefix + ' a').on("click", function (event) {
        event.preventDefault();
        var id  = $(this).attr("href");
        var top = $(id).offset().top - 40;
        $('html, body').animate({scrollTop: top}, 1000);
    });

    /**
     * Sliders
     */
    $('.writers_slider' +prefix + ' .owl-carousel').owlCarousel({
        loop:true,
        margin:25,
        nav:true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplaySpeed :1000,
        autoplayHoverPause:true,
        responsive:{0:{items:1, margin:10,}, 600:{items:2, margin:10,}, 1000:{items:3}}
    });
    $('.testimonials_slider' +prefix + ' .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        items:1,
        autoplay:true,
        autoplayTimeout:5000,
        autoplaySpeed :700,
        autoplayHoverPause:true
    });

    $(window).on('load',function () {
        /**
         * Buttons and forms
         */
        var utm = null;
        $('[data-order-btn]').on("click", function() {
            utm = redirectObject.order_btn_utm;
            window.location.href = redirectObject.domain+'/order'+utm;
        });
        $('[data-login-btn]').on("click", function() {
            var utm = '';
            redirectObject.login_btn_utm ? utm = redirectObject.login_btn_utm : utm = '';
            window.location.href = redirectObject.domain+'/?login-first=1'+utm;
        });
        $('[data-hire-writer]').on("click", function() {
            if(redirectObject.writer_redirect == 'order') {
                var utm = '';
                redirectObject.writer_order_form ? utm = redirectObject.writer_order_form : utm = '';
                window.location.href = redirectObject.domain+"/order"+utm;
            }
            else {
                var id = $(this).attr('data-hire-writer');
                var utm = '';
                redirectObject.writer_single_writer_page ? utm = redirectObject.writer_single_writer_page : utm = '';
                window.location.href = redirectObject.domain+"/writer/public/"+id + utm;
            }
        });
        $('[data-form]').on("submit", function() {
            var form = $(this),
                email = form.find(".header-order-email").val(),
                paper = form.find(".paper_type").attr('data-select-id'),
                utm_with_email = '',
                utm_no_email = '';

            redirectObject.form_with_email_utm ? utm_with_email = redirectObject.form_with_email_utm : false;
            redirectObject.form_no_email_utm ? utm_no_email = redirectObject.form_no_email_utm : false;

            var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-order?foc_u_email="+ email +"&foc_o_paper_type=" + paper + utm_with_email : redirectObject.domain + "/order?foc_o_paper_type=" + paper + utm_no_email;
            form.attr('action',link);
        });

        $("[data-full-form]").on("submit", function() {
            var form = $(this),
                email = form.find(".header-order-email").val(),
                paper = form.find(".paper_type").attr('data-select-id'),
                subject = form.find(".subject").attr('data-select-id'),
                pages = form.find(".header-order-pages").val(),
                date = form.find(".header-order-date").val(),
                time = form.find(".header-order-time").val(),
                utm_with_email = '',
                utm_no_email = '';

            redirectObject.form_with_email_utm ? utm_with_email = redirectObject.form_with_email_utm : false;
            redirectObject.form_no_email_utm ? utm_no_email = redirectObject.form_no_email_utm : false;

            var parameners = "foc_o_paper_type=" + paper + "&foc_o_subject=" + subject + "&foc_o_pages=" + pages + "&foc_o_deadline=" + date + '+' +time + '%3A00';
            var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-order?foc_u_email="+ email + '&' + parameners + utm_with_email : redirectObject.domain + "/order?" + parameners + utm_no_email;
            form.attr('action',link);
        });
    })
    
});